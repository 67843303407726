
import { Controller } from 'stimulus'

import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Navigation)

export default class extends Controller {
  static targets = ['next', 'prev']

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 40,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      }
    })
  }
}